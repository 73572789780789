import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import Image from "../../shared/assets/images/AboutImg.png";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { db } from "../firebase/firebase";
import { ref, get } from "firebase/database";
import AppThemeContext from "../../providers/AppThemeContext";
interface AboutProps {
  handleLoadingAction: any
}

const About: React.FC<AboutProps> = ({handleLoadingAction}) => {
  const aboutTextsRef = ref(db, "valsta/aboutTexts");
  const [texts, setTexts] = useState<any>({});
  const [isLoading, setisLoading] = useState(true)
  useEffect(() => {
    handleLoadingAction(isLoading);
  }, [handleLoadingAction, isLoading]);

  const { palette } = useTheme();
  const navigate = useNavigate();
  const { padding } = useContext(AppThemeContext);
  const { paddingLeft, paddingRight } = padding;

  useEffect(() => {
    setisLoading(true)
    get(aboutTextsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const aboutTexts = snapshot.val();
          setTexts(aboutTexts);
        } else {
          console.log("About Texts do not exist.");
        }
        setisLoading(false)
      })
      .catch((error) => {
        setisLoading(false)
        console.error("Error fetching About Texts:", error);
      });
  }, []);

  return (
    <Box
      className="about-container"
      id="about"
      
      sx={{ backgroundColor: "#f3f5f9" }}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
    >
      <Box>
        <Box className="about-us-title">
          <h1>Om oss</h1>
        </Box>
        <Box className="about-us-box">
          <img src={Image} />
          <Box className="about-us-content-box">
            {texts.desc?.map(
              (item: string, index: number) =>
                index < 8 && (
                  <Typography key={index} color={palette.text.secondary}>
                    {item}
                  </Typography>
                )
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
