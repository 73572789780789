import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom/dist";
import { useMediaQuery, Box, Theme } from "@mui/material";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import "./styles.css";
import AppThemeContext from "../../providers/AppThemeContext";
import Loader from "../Loader/loader";

interface WrapperProps {
  isLoading: boolean;
  handleMainPageLoading: any;
}

const Wrapper: React.FC<WrapperProps> = ({ isLoading, handleMainPageLoading }) => {
  const location = useLocation();
  const [padding, setPadding] = useState<React.CSSProperties>({
    paddingLeft: "7rem",
    paddingRight: "7rem",
  });
  const isMdScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );
  useEffect(() => {
    if (setPadding) {
      setPadding({
        paddingLeft: isMdScreen ? "7rem" : "1rem",
        paddingRight: isMdScreen ? "7rem" : "1rem",
      });
    }
  }, [isMdScreen]);

  const isHomePage = location.pathname === "/"; // Adjust based on your route setup

  const handleLogoLoad = (action: boolean)=>{
    console.log("logo", action)
    handleMainPageLoading(action)
  }

  return (
    <AppThemeContext.Provider value={{ padding, setPadding }}>
      <Box>
        {isHomePage ? (
          <>
            {!isLoading?<Navbar/>:<Loader/>}
            <Box>
              <Outlet />
            </Box>
          </>
        ) : (
          <>
            <Navbar/>
            <Box style={padding}>
              <Outlet />
            </Box>
          </>
        )}
        <Footer />
      </Box>
    </AppThemeContext.Provider>
  );
};

export default Wrapper;
