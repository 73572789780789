import { storage } from "./firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";

export const fetchImages = async () => {
  const storageRef = ref(storage, "images/");
  const result = await listAll(storageRef);

  const urlPromises = result.items.map((imageRef) => getDownloadURL(imageRef));

  return Promise.all(urlPromises);
};

export const fetchCarouselImages = async () => {
  const storageRef = ref(storage, "valsta/images/carousel");
  const result = await listAll(storageRef);
  const urlPromises = result.items.map((imageRef) => getDownloadURL(imageRef));
  return Promise.all(urlPromises);
};

export const fetchTreatmentsImages = async () => {
  const storageRef = ref(storage, "valsta/images/treatments");
  const result = await listAll(storageRef);
  const urlPromises = result.items.map((imageRef) => getDownloadURL(imageRef));
  return Promise.all(urlPromises);
};

export const fetchLogo = async () => {
  const storageRef = ref(storage, "valsta/images/logo");
  const result = await listAll(storageRef);
  const urlPromises = result.items.map((imageRef) => getDownloadURL(imageRef));
  return Promise.all(urlPromises);
};
