import { useContext, useEffect, useState } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { Call } from "@mui/icons-material";
import { ref, get } from "firebase/database";
import { db } from "../firebase/firebase";
import AppThemeContext from "../../providers/AppThemeContext";
import HeroImg from "../../shared/assets/images/HeroImg.png";
import "./styles.css";

interface HeroProps {
  handleLoadingAction: any;
}

const Hero: React.FC<HeroProps> = ({ handleLoadingAction }) => {
  const heroTextsRef = ref(db, "valsta/heroTexts");
  const contactRef = ref(db, "valsta/contact/info");
  const [texts, setTexts] = useState<any>({});
  const [contact, setContact] = useState<any>({});

  const { palette } = useTheme();
  const { padding } = useContext(AppThemeContext);
  const { paddingLeft, paddingRight } = padding;
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    handleLoadingAction(isLoading);
  }, [handleLoadingAction, isLoading]);

  useEffect(() => {
    setisLoading(true);
    get(heroTextsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const heroTexts = snapshot.val();
          setTexts(heroTexts);
        } else {
          console.log("Hero Texts do not exist.");
        }
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
        console.error("Error fetching Hero Texts:", error);
      });
    get(contactRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const contact = snapshot.val();
          setContact(contact);
        } else {
          console.log("Contact info do not exist.");
        }
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
        console.error("Error fetching Contact Info:", error);
      });
  }, []);

  const redirectToURL = () => {
    const newWindow = window.open(
      "https://www.muntra.com/valsta-tandlakarklinik/c/4519?language=sv",
      "_blank"
    );
    newWindow?.focus();
  };

  return (
    <Box
      className="hero-main-container"
      id="home"
      pl={paddingLeft}
      pr={paddingRight}
    >
      <Box className="hero-left-box">
        <h1>{texts?.heroTitle}</h1>
        <Typography color={palette.text.secondary}>
          {texts?.heroDesc}
        </Typography>
        <Stack className="hero-left-btns-box" spacing={4}>
          <Button
            onClick={redirectToURL}
            className="menu-appointment-btn"
            style={{ marginLeft: 0, fontSize: "1.2rem" }}
          >
            Boka tid
          </Button>
          <Button
            className="menu-contact-no-btn"
            style={{ marginLeft: 0, fontSize: "1.2rem" }}
          >
            <Call />
            {contact?.phone}
          </Button>
        </Stack>
      </Box>
      <Box className="hero-right-box">
        <img src={HeroImg} alt="Hero" height="auto" />
      </Box>
    </Box>
  );
};

export default Hero;
