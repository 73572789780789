import {Box, Stack, useTheme} from "@mui/material";

const PrivacyPolicy = () => {
    const {palette} = useTheme();
    return (
        <Stack>
            <Box>
                <h1>Privacy Policy</h1>
            </Box>
            <Box p={"5rem"} color={palette.text.secondary}>
                <p>This privacy policy was developed to ensure transparency and to protect the privacy of our patients
                    and users. It outlines how we collect, use, store, and safeguard your personal data, in compliance
                    with applicable data protection laws and regulations in Sweden. The policy serves to inform you
                    about your rights concerning your personal information and how you can exercise those rights.
                    Additionally, it demonstrates our commitment to maintaining the confidentiality and security of any
                    personal data shared with us, whether through in-person interactions, online platforms, or other
                    means.</p>

                <br/>

                <p>Our goal is to create a trusting relationship with our patients by being clear about how we handle
                    your sensitive information while providing high-quality dental care and online services. </p>

                <br/>

                <p><strong>Why Do We Need Your Personal Data?</strong> <br/><br/>
                    As a dental health clinic, we collect your personal data to provide our services completely and
                    accurately. This information is necessary for understanding your treatment processes, planning
                    treatments, offering personalized healthcare solutions, and providing services such as appointment
                    reminders. We also need personal data to prepare legally required documents and reports.</p>

                <br/>

                <p><strong>On the other hand we also collect your some personal data through cookies on our website is
                    used for
                    the following purposes:</strong></p>

                <br/>

                <p>Enhancing User Experience: Cookies help us remember your preferences and settings, such as language
                    selection, so that you have a smoother and more personalized browsing experience.</p>

                <br/>
                <p>Website Performance and Analytics: We use cookies to gather data about how our website is used, such
                    as the pages visited and the duration of visits. This allows us to analyze website traffic and
                    improve our services by identifying which areas of the site are most effective or need
                    enhancements.</p>
                <br/>

                <p><strong>Personalized Content and Marketing: </strong> Cookies enable us to offer relevant content and
                    promotions based
                    on your interests and browsing habits. This ensures that the information you receive is tailored to
                    your preferences.</p>

                <br/>
                <p><strong>Security and Fraud Prevention: </strong> Cookies are used to enhance security by verifying
                    users and
                    preventing fraudulent activity. This helps protect your personal data and ensures the integrity of
                    your interactions with our site.</p>

                <br/>
                <p><strong>Facilitating Online Services: </strong> For certain features, such as scheduling appointments
                    or filling out
                    forms online, cookies are used to store session data, making it easier for you to complete tasks
                    without re-entering information.
                    Of course you have the option to manage or disable cookies through their browser settings. However,
                    disabling cookies may affect the functionality and user experience of the website.</p>
                <br/>

                <br/>
                <p><strong>How Do We Collect the Data?</strong> <br/><br/>
                    Your personal data is collected through various channels: <br/>
                    <ul>
                        <li>Forms and medical history documents filled out directly by you.</li>
                        <li>Treatment history information from our clinic or other healthcare providers.</li>
                        <li>Data collected through cookies on our website when you use our online services.</li>
                        <li>Information shared by you via phone, email, or in-person conversations.</li>
                    </ul>
                </p>

                <br/>
                <p><strong>Where Do We Store This Information and How Do We Ensure Its Security? </strong> <br/><br/>
                    Your personal data is securely stored both physically and digitally. Physical documents are kept in
                    locked cabinets, while digital data is stored on secure, encrypted servers. Our security measures
                    include technological and administrative safeguards to limit access to the data, prevent data
                    breaches, and protect against unauthorized access.</p>

                <br/>
                <p><strong>Our Responsibilities Regarding the Disclosure of This Information </strong> <br/><br/>
                    We do not share your personal data with third parties except in cases of legal obligations. If any
                    part or all of your personal data needs to be shared with other healthcare providers or business
                    partners, we obtain your explicit consent. In the case of legal processes, court orders, or official
                    requests, we will disclose the minimum necessary data required.</p>


                <p><strong>How Do We Destroy Data When It Is No Longer Needed? </strong> <br/><br/>
                    Personal data that is no longer needed for service purposes or whose usage is no longer necessary is
                    securely destroyed in accordance with legal regulations. Digital data is securely deleted, while
                    physical documents are destroyed using shredders or other secure means.</p>

                <p><strong>Our Cookie Policy </strong> <br/><br/>
                    Our website uses cookies to improve user experience and enhance the quality of our services. Cookies
                    help remember user preferences, collect browsing information, and speed up online transactions.
                    Users can limit or disable the use of cookies through their browser settings; however, this may
                    limit some website features.
                    Your Rights Regarding the Use of Personal Information</p>


                <p>As a data subject, you have the right to know how your personal data is being used, to update your
                    data, request the correction of inaccurate or incomplete data, object to the processing of your data
                    in certain circumstances, and request the deletion of your data. You can exercise all your rights by
                    contacting our clinic.
                    Changes to the Privacy Policy Over Time</p>


                <p>Our privacy policy may be updated from time to time due to legal requirements or changes in our
                    services. These changes will be published on our clinic’s website, and if necessary, you will be
                    notified. We recommend reviewing the current version of our privacy policy regularly. </p>
            </Box>
        </Stack>
    );
};

export default PrivacyPolicy;
