import React from "react";
import { ReactComponent as Map } from "../../shared/assets/icons/map.svg";
import { ReactComponent as Clock } from "../../shared/assets/icons/clock.svg";
import { ReactComponent as Mail } from "../../shared/assets/icons/mail.svg";
import { ReactComponent as Phone } from "../../shared/assets/icons/phone.svg";

const contactInfo = (texts: any) => {
  return [
    {
      key: "location",
      name: "Vår Adress",
      value: `${texts?.location} & ${texts?.email} & ${texts?.phone}`,
      icon: <Map />,
    },
    {
      key: "timing",
      name: "Våra Öppettider",
      value: texts?.timing,
      icon: <Clock />,
    },
    // {
    //   name: "email",
    //   value: texts?.email,
    //   icon: <Mail />,
    // },
    // {
    //   value: texts?.phone,
    //   icon: <Phone />,
    // },
  ];
};

export default contactInfo;
