import {Route, Routes} from "react-router-dom";
import Home from "../pages/Home";
import NotFound from "../components/notFound/NotFound";
import Wrapper from "../components/wrapper/Wrapper";
import Treatment from "../pages/Treatments";
import AboutDetail from "../components/about/AboutDetail";
import Contact from "../components/contact/Contact";
import {useState} from 'react';
import TreatmentDetails from "../components/treatment/TreatmentDetails";
import PrivacyPolicy from "../pages/PrivacyPolicy";


const AppRoutes = () => {
    const [isLoading, setisLoading] = useState(true)
    const handleMainPageLoading = (action: boolean) => {
        setisLoading(action)
    }
    return (
        <Routes>
            <Route path="" element={<Wrapper isLoading={isLoading} handleMainPageLoading={handleMainPageLoading}/>}>
                <Route index element={<Home handleMainPageLoading={handleMainPageLoading}/>}/>
                <Route path="/behandlingar" element={<Treatment/>}/>
                <Route path="/behandlingar/:id" element={<TreatmentDetails/>}/>
                <Route path="/kontakt" element={<Contact/>}/>
                <Route path="/om-oss" element={<AboutDetail/>}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
            </Route>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    );
};

export default AppRoutes;
