import { Box, Stack, Typography, useTheme } from "@mui/material";
import "./styles.css";
import { db } from "../firebase/firebase";
import { ref, get } from "firebase/database";
import aboutItems from "./aboutItems";
import { useEffect, useState } from "react";
import Loader2 from "../Loader/loader2";

const AboutDetail = () => {
  const aboutTextsRef = ref(db, "valsta/aboutTexts");
  const [texts, setTexts] = useState<any>({});
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const { palette } = useTheme();

  useEffect(() => {
    setIsLoading(true);
    get(aboutTextsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const aboutTexts = snapshot.val();
          setTexts(aboutTexts);
        } else {
          console.log("About Texts do not exist.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching About Texts:", error);
      });
  }, []);

  const items = aboutItems(texts);

  return (
    <>
      {!isLoading ? (
        <Box className="about-container" id="about">
          <Stack>
            <Box className="about-us-quote">
              <h1>{items.quote}</h1>
            </Box>
            <Box className="about-us-content-box">
              {items.points.map((item: any, index: number) => (
                <Typography key={index} color={palette.text.secondary}>
                  {item}
                </Typography>
                
              ))}
            </Box>
          </Stack>
        </Box>
      ) : (
        <Loader2 />
      )}
    </>
  );
};

export default AboutDetail;
