import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Typography variant="h2" align="center" gutterBottom>
      Sidan hittas inte
      </Typography>
      <Typography
        variant="h5"
        color="textSecondary"
        align="center"
        gutterBottom
      >
        hoppsan! Sidan du letar efter finns inte.
      </Typography>
      <Button component={Link} to="/" variant="text" color="primary">
      Start
      </Button>
    </Box>
  );
};

export default NotFound;
