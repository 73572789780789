import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

import {
  firebaseAppID,
  firebaseAuthDomain,
  firebaseKey,
  firebaseMeasurementID,
  firebaseMessaginSenderID,
  firebaseProjectId,
  firebaseStorageBucket,
  firebaseDatabaseURL,
} from "../../config";

const firebaseConfig = {
  apiKey: firebaseKey,
  authDomain: firebaseAuthDomain,
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMessaginSenderID,
  appId: firebaseAppID,
  measurementId: firebaseMeasurementID,
  databaseURL: firebaseDatabaseURL,
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getDatabase(app);

export { app, storage, db };
