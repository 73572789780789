import React from 'react'
import './styles.css'
import Logo from '../../shared/assets/images/Logo.png'

const Loader = () => {
  return (
    <div className="loader">
        <img src={Logo}/>
    </div>
  )
}

export default Loader