import Hero from "../components/hero/Hero";
import About from "../components/about/About";
import ImageSlider from "../components/Carousel/ImageSlider";
import Contact from "../components/contact/Contact";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Loader from "../components/Loader/loader";

interface HomeProps {
  handleMainPageLoading: any;
}

const Home: React.FC<HomeProps> = ({ handleMainPageLoading }) => {
  const handleLoadingAction = (action: boolean) => {
    handleMainPageLoading(action);
  };
  useEffect(() => {}, [handleMainPageLoading]);

  return (
    <>
        <Stack>
          <Hero handleLoadingAction={handleLoadingAction} />
          <ImageSlider handleLoadingAction={handleLoadingAction} />
          <About handleLoadingAction={handleLoadingAction} />
          <Contact />
        </Stack>
    </>
  );
};

export default Home;
