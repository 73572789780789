import { Box, Grid, Typography, useTheme } from "@mui/material";
import "./styles.css";
import TreatmentItems from "./TreatmentItems";
import { get, ref } from "@firebase/database";
import { db } from "../firebase/firebase";
import { useEffect, useState } from "react";
import Loader2 from "../Loader/loader2";
import { useNavigate } from 'react-router-dom';


const TreatmentGrid = () => {
  const [items, setItems] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const { palette } = useTheme();
  const treatmentsRef = ref(db, "valsta/treatments");
  const nav = useNavigate()

  function formatString(str: string): string {
    return str.replace(/\s+/g, '-').toLowerCase();
  }
  
  useEffect(() => {
    setIsLoading(true)
    get(treatmentsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const treatmentsTexts = snapshot.val();
          setItems(treatmentsTexts);
        } else {
          console.log("Treatments Texts do not exist.");
        }
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error("Error fetching Treatment Texts:", error);
      });
  }, []);

  const navigateToDetails = (event: any, item:any)=>{
    event.stopPropagation();
    nav(`/behandlingar/${formatString(item.name)}`, { state: item })
  }

  return (
    <>
    {!isLoading?
    <Box>
    <Typography variant="h4" my={4} color={palette.text.secondary}>
    Behandlingar
    </Typography>
    <Box>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {items?.map((item: any, index: any) => (
          <Grid item xs={4} sm={8} md={4} key={index}>
           <Box onClick={(event)=>navigateToDetails(event, item)}>
           <TreatmentItems title={item.name} imgSrc={item.image} />
           </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  </Box>:<Loader2 />}
    </>
  );
};

export default TreatmentGrid;
