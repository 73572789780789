import { useEffect, useState } from "react";
import "./Footer.css";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material/";
import Logo from "../../shared/assets/images/Logo.png";
import { Link } from "react-router-dom";
import { db } from "../firebase/firebase";
import { ref, get } from "firebase/database";

const Footer = () => {
  const socialLinksRefs = ref(db, "valsta/contact/socials");
  const [texts, setTexts] = useState<any>({});

  useEffect(() => {
    get(socialLinksRefs)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const contactTexts = snapshot.val();
          setTexts(contactTexts);
        } else {
          console.log("Social Links do not exist.");
        }
      })
      .catch((error) => {
        console.error("Error fetching Social Links:", error);
      });
  }, []);

  return (
    <div className="footer-container">
      <div className="footer-logo">
        <img src={Logo} alt="Teeth Logo" className="logo-img" />
      </div>
      <div className="footer-menu">
        <Link to="/" className="menu-item">
          Start
        </Link>
        <Link to="/om-oss" className="menu-item">
        Om oss
        </Link>
        <Link to="/behandlingar" className="menu-item">
        Behandlingar
        </Link>
        <Link to="/kontakt" className="menu-item">
          Kontakt
        </Link>
        <Link to="/privacy-policy" className="menu-item">
          Privacy Policy
        </Link>
      </div>
      <div className="footer-social">
        <a
          href={texts?.twitter}
          target="_blank"
          rel="noreferrer"
          className="social-icon"
        >
          <Twitter />
        </a>
        <a
          href={texts?.instagram}
          target="_blank"
          rel="noreferrer"
          className="social-icon"
        >
          <Instagram />
        </a>
        <a
          href={texts?.facebook}
          target="_blank"
          rel="noreferrer"
          className="social-icon"
        >
          <Facebook />
        </a>
        <a
          href={texts?.linkedIn}
          target="_blank"
          rel="noreferrer"
          className="social-icon"
        >
          <LinkedIn />
        </a>
      </div>
    </div>
  );
};

export default Footer;
