import { useState, useContext, useEffect } from "react";
import { Box, Button, Stack, TextField } from "@mui/material";
import Carousel1 from "../../shared/assets/images/ContactHeadCut.png";
import "./styles.css";
import { toast } from "react-toastify";
import MapComponent from "./MapComponent";
import contactInfo from "./contactInfo";
import { db } from "../firebase/firebase";
import { ref, get } from "firebase/database";
import AppThemeContext from "../../providers/AppThemeContext";
import Loader2 from "../Loader/loader2";
import axios from 'axios'

const Contact = () => {
  const contactTextsRef = ref(db, "valsta/contact");
  const [texts, setTexts] = useState<any>({});

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const { padding } = useContext(AppThemeContext);
  const { paddingLeft, paddingRight } = padding;
  const [isLoading, setIsLoading] = useState<Boolean>(false);

  const handleSubmit = async () => {
    if (name.trim() === "" || email.trim() === "" || message.trim() === "") {
      toast.error("Please fill in all fields.");
    } else {
      setIsLoading(true);

      try {
        console.log("acce");
        const resp = await axios
          .post(
            `https://thas-mail-service.com/send-email/`,
            {
              subject: `New Message from ${name} at Valsta Tandlakarklinik.`,
              name: name,
              id: "66333a8d-5b9b-4da9-bd1d-6b6cb6e25d93",
              message: message,
              reply_to: email,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization:
                  "Token JKDAQY6R7c0MjqfMO9fwW9YttCQpupbGwq7RCz9U0QjajjXXlvGGTsskC9sIQzEf",
              },
            }
          )
          .then((response: any) => {
            toast("Email sent successfully. ");
            setIsLoading(false);
            return response;
          });
      } catch (error) {
        toast("something went wrong.");
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    get(contactTextsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const contactTexts = snapshot.val();
          setTexts(contactTexts);
        } else {
          console.log("Contact Texts do not exist.");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching Contact Texts:", error);
      });
  }, []);

  const items = contactInfo(texts.info) || [];
  const contact: any = {};

  items?.forEach((item) => {
    const originalString = item?.value;
    if (item.key === "location") {
      const parts = originalString?.split("&");
      const trimmedParts = parts?.map((part: any) => part.trim());
      contact[item.key] = trimmedParts;
    } else {
      const parts = originalString?.split(",");
      const trimmedParts = parts?.map((part: any) => part.trim());
      contact[item.key] = trimmedParts;
    }
  });

  return (
    <>
      <Stack
        id="contact"
        sx={{ backgroundColor: "#fffbf2" }}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
      >
        <Box className="contact-container" sx={{ margin: "5rem 0 0 0" }}>
          <Stack className="contact-form">
            <img src={Carousel1} alt="" />
            <p id="title">{texts.title}</p>
            <p>Name</p>
            <TextField
              name="name"
              type="text"
              required
              variant="standard"
              className="input-field"
              placeholder=""
              InputProps={{
                disableUnderline: true,
              }}
              onChange={(e) => setName(e.target.value)}
            />
            <p>Email</p>
            <TextField
              name="email"
              type="email"
              required
              variant="standard"
              className="input-field"
              placeholder=""
              InputProps={{
                disableUnderline: true,
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <p>Meddelande</p>
            <TextField
              name="message"
              type="text"
              required
              multiline
              variant="standard"
              className="message"
              placeholder=""
              InputProps={{
                disableUnderline: true,
              }}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button type="submit" onClick={()=>handleSubmit()} disabled={!!isLoading}>
              {isLoading ? "Loading..." : "skicka"}
            </Button>
          </Stack>
          <MapComponent position={texts.markerPosition} />
        </Box>
        <Box className="contact-footer">
          {/* <p id="title">{texts.subtitle}</p> */}
          <Box className="contact-box">
            {items.map((item, index) =>
              item.key === "timing" && contact.timing ? (
                <Box key={index} className="contact-info">
                  <h2>{item.name}</h2>
                  <p>{contact?.timing[0]}</p>
                  <p>{contact?.timing[1]}</p>
                </Box>
              ) : (
                <Box key={index} className="contact-info">
                  <h2>{item.name}</h2>
                  <p>{contact?.location[0]}</p>
                  <p>{contact?.location[1]}</p>
                  <p>Tel: {contact?.location[2]}</p>
                </Box>
              )
            )}
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default Contact;
