import React from 'react';

interface PaddingContextProps {
  padding: React.CSSProperties;
  setPadding: (padding: React.CSSProperties) => void;
}

const AppThemeContext = React.createContext<PaddingContextProps>({
  padding: { paddingLeft: "7rem", paddingRight: "7rem" },
  setPadding: () => {},
});

export default AppThemeContext;
