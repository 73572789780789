import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery, Theme, Box } from "@mui/material/";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { useContext } from "react";
import AppThemeContext from "../../providers/AppThemeContext";
import "./styles.css";
import { fetchCarouselImages } from "../firebase/imageUtils";
import { EffectCoverflow, Pagination, Autoplay, Navigation } from "swiper";

interface Props {
  handleLoadingAction: any
}


const ImageSlider: React.FC<Props> = ({handleLoadingAction})=>{
  
  const isMdScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );
  const { padding } = useContext(AppThemeContext);
  const { paddingLeft, paddingRight } = padding;
  const [imageUrls, setImageUrls] = useState([""]);
  const [isLoading, setisLoading] = useState(true)
  useEffect(() => {
    handleLoadingAction(isLoading);
  }, [handleLoadingAction, isLoading]);

  useEffect(() => {
    setisLoading(true)
    async function getImageUrls() {
      try {
        const urls = await fetchCarouselImages();
        setImageUrls(urls);
        setisLoading(false)
      } catch (error) {
        setisLoading(false)
        console.error("Error fetching images:", error);
      }
    }

    getImageUrls();
  }, []);

  return (
    <>
      <Box
     sx={{ backgroundColor: "#fffbf2", margin: "4rem 0 3rem 0"}}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
      >
        <Swiper
          effect={isMdScreen ? "coverflow" : "fade"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={isMdScreen ? 3 : 1}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          initialSlide={isMdScreen ? 1 : 0}
          pagination={true}
          modules={
            isMdScreen
              ? [EffectCoverflow, Autoplay, Pagination, Navigation]
              : [Autoplay, Pagination, Navigation]
          }
          className="mySwiper"
          navigation={true}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        >
          {imageUrls.map((imageUrl, index) => (
            <SwiperSlide key={index}>
              <img src={imageUrl} alt={`Img ${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </>
  );
}


export default ImageSlider