import { Box, Stack, Typography, CardMedia, useTheme } from "@mui/material";
import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";

const TreatmentDetails = () => {
  const { palette } = useTheme();
  const location = useLocation();
  const nav = useNavigate();
  const { state } = location;
  console.log(state);

  return (
    <Box>
      <Typography component="p" my={4} color={palette.text.secondary}>
        <span
          onClick={() => {
            nav("/behandlingar");
          }}
          style={{ cursor: "pointer" }}
        >
          Behandlingar
        </span>
        /{state.name}
      </Typography>

      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 1, md: 2 }}
        sx={{ display: 'flex', justifyContent: 'space-around' }}
      >
        <Box width={"100%"}>
        <CardMedia
          component="img"
          height="500"
          
          image={state.image}
          alt="green iguana"
        />
        </Box>
        <Box p={"5rem"} color={palette.text.secondary}>
        {state.description}
        </Box>
      </Stack>
    </Box>
  );
};

export default TreatmentDetails;
