import { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Container,
  Button,
  Box,
  useMediaQuery,
  Theme,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Stack,
} from "@mui/material/";
import { useNavigate } from "react-router-dom";

import { Menu } from "@mui/icons-material/";
import { menuItems } from "./NavbarItems";
import "./styles.css";
import Logo from "../../shared/assets/images/Logo.png";

type MenuItemsProps = {
  title: string;
  link: string;
};



const Navbar = ()=>{
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);


  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const redirectToURL = () => {
    const newWindow = window.open('https://www.muntra.com/valsta-tandlakarklinik/c/4519?language=sv', '_blank');
    newWindow?.focus();
  };

  // useEffect(() => {
  //   setisLoading(true)
  //   async function getImageUrls() {
  //     try {
  //       const urls = await fetchLogo();
  //       setImageUrls(urls);
  //       setisLoading(false)
  //     } catch (error) {
  //       setisLoading(false)
  //       console.error("Error fetching logo:", error);
  //     }
  //   }
  //   getImageUrls();
  // }, []);

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: "#fffbf2",
        padding: isMdScreen?".6rem 0 .6rem 0":".8rem 0 .8rem 0",
        boxShadow: "0 0.2rem 0.3rem rgba(0, 0, 0, 0.15)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {isMdScreen ? (
            <>
              <img
                style={{ width: "15%" }}
                onClick={() => navigate("/")}
                src={Logo}
                alt=""
              />
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {menuItems.map((item: MenuItemsProps) => (
                  <Button
                    className="menu-btn"
                    sx={{ color: theme.palette.text.secondary }}
                    onClick={() => navigate(item.link)}
                    key={item.link}
                  >
                    {item.title}
                  </Button>
                ))}
              </Box>
              <Box className="action-btn-container">
                {/* <Button className="menu-contact-no-btn">
                  <Call />
                  +212 5 28 22 12 34
                </Button> */}
                <Button
                onClick={redirectToURL}
                  className="menu-appointment-btn"
                  style={{ marginLeft: "2rem" }}
                >
                 Boka tid
                </Button>
              </Box>
            </>
          ) : (
            <>
              <img
                style={{ width: "47%" }}
                onClick={() => navigate("/")}
                src={Logo}
                alt=""
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <IconButton
                  edge="end"
                  color="primary"
                  aria-label="menu"
                  onClick={toggleMobileMenu}
                >
                  <Menu />
                </IconButton>
              </Box>
              <Drawer
                anchor="right"
                open={mobileMenuOpen}
                onClose={toggleMobileMenu}
              >
                <List sx={{ padding: "10px" }}>
                  {menuItems.map((item: MenuItemsProps) => (
                    <ListItem
                      key={item.link}
                      onClick={() => {
                        navigate(item.link);
                        toggleMobileMenu();
                      }}
                    >
                      <ListItemText primary={item.title} />
                    </ListItem>
                  ))}
                  <Stack spacing={2}>
                    {/* <Button className="menu-contact-no-btn">
                      <Call /> +212 5 28 22 12 34
                    </Button> */}
                    <Button className="menu-appointment-btn"  onClick={redirectToURL}>
                    Boka tid
                    </Button>
                  </Stack>
                </List>
              </Drawer>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
