import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { googleMapApiKey } from "../../config";
import { Box } from "@mui/material";

const containerStyle = {
  width: "100%",
  height: "100%",
};

function MapComponent(props: { position: any }) {
  const { position } = props;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapApiKey || "",
  });
  const markerPosition = {
    lat: parseFloat(position?.lat) || 59.616714,
    lng: parseFloat(position?.lng) || 17.823871,
  };

  return isLoaded ? (
    <Box className="map-component">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerPosition}
        zoom={20}
        options={{ mapTypeControl: false }}
      >
        <Marker position={markerPosition} />
      </GoogleMap>
    </Box>
  ) : (
    <div>Loading...</div>
  );
}

export default MapComponent;
