export const menuItems = [
  { title: "Start", link: "/" },
  { title: "Behandlingar", link: "/behandlingar" },
  { title: "Om oss", link: "/om-oss" },
  { title: "Kontakt", link: "/kontakt" },
];




