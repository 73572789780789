import React from "react";
import "./styles2.css";

const Loader2 = () => {
  return (
    <div className="snippet" data-title="dot-elastic">
      <div className="stage">
        <div className="dot-elastic"></div>
      </div>
    </div>
  );
};

export default Loader2;
