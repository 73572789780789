export const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const firebaseKey = process.env.REACT_APP_FIREBASE_KEY;
export const firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const firebaseStorageBucket =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const firebaseMessaginSenderID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const firebaseAppID = process.env.REACT_APP_FIREBASE_APP_ID;
export const firebaseMeasurementID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const firebaseDatabaseURL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
