import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export default function TreatmentItems(props: {
  title: string;
  imgSrc: string;
}) {
  const { title, imgSrc } = props;
  return (
    <Card sx={{ width: "100%" }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="250"
          image={imgSrc}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" textAlign={"center"}>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
