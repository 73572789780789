import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./App.routes";
import { AppThemeProvider } from "../providers/AppThemeProvider";
import "./App.css";

const App = () => {
  return (
    <AppThemeProvider>
      <ToastContainer />
      <AppRoutes />
    </AppThemeProvider>
  );
};

export default App;
