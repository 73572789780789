import { Stack } from "@mui/material";
import TreatmentGrid from "../components/treatment/TreatmentGrid";

const Treatment = () => {
  return (
    <Stack>
        <TreatmentGrid />
    </Stack>
  );
};

export default Treatment;
